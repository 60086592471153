import React, { useEffect, useState } from 'react'

import DesktopSteps from './desktop-steps'
import MobileSteps from './mobile-steps'

import './index.css'

interface Props {
  backgroundColor: string
  stepItems: StepItemProps[]
}

const Steps = ({ backgroundColor, stepItems }: Props) => {
  const [isMobile, setIsMobile] = useState(false)

  const handleWindowSizeChange = () => {
    if (window.innerWidth <= 640) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  // const renderStepItems = () => {
  //   return stepItems.map((stepItem, i) => {

  //     let isRight = false;
  //     if ((i+1) % 2 === 0) isRight = true;

  //     return (
  //       <div className={`w-full sm:flex sm:flex-row ${(isRight) ? 'justify-content-end': 'justify-content-start'}`}>
  //         <div className={`w-11/12 ${(isRight) ? 'margin-right-adjustment': 'margin-left-adjustment'} sm:w-1/2`}>
  //           <StepItem
  //             imgSrc={stepItem.imgSrc}
  //             prominentText={stepItem.prominentText}
  //             prominentTextColor={stepItem.prominentTextColor}
  //             descriptionText={stepItem.descriptionText}
  //             descriptionTextColor={stepItem.descriptionTextColor}
  //             key={`${stepItem.prominentText}-${i}`}
  //           />
  //         </div>
  //       </div>
  //     );
  //   });
  // }

  const renderComps = () => {
    if (isMobile) {
      return (
        <div className="container relative mx-auto sm:hidden" id="steps-section-mobile">
          <MobileSteps Items={stepItems} backgroundColor={backgroundColor} />
        </div>
      )
    }

    return (
      <div className="container mx-auto hidden pt-10 pb-20 sm:block" id="steps-section">
        <DesktopSteps Items={stepItems} backgroundColor={backgroundColor} />
      </div>
    )
  }

  return (
    <div className="steps-mega-parent w-full overflow-hidden" style={{ backgroundColor }}>
      {renderComps()}
    </div>
  )
}

export default Steps
